<template>
  <section>
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0"
    >
      <Logo class="mb-6" />
      <div
        class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
          >
            Welcome!
          </h1>

          <div class="flex justify-between">
            <UButton
              :label="`Sign in with ${provider?.name}`"
              v-for="provider in providers"
              :key="provider?.id"
              @click="signIn(provider?.id, { callbackUrl: '/dashboard' })"
            />
          </div>

          
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
definePageMeta({
  auth: {
    unauthenticatedOnly: true,
  },
});
const { signIn, getProviders } = useAuth();
const providers = await getProviders();
if (!providers || Object.keys(providers).length === 0) {
  throw createError({
    statusCode: 500,
    statusMessage: "Failed to get providers",
  });
}
</script>
