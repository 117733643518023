<template>
  <a
    href="https://www.youtube.com/playlist?list=PLQdX2Upwv8S6C7C75dysmBzWa0Nwnjm4W"
    target="_blank"
    class="flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
  >
    <div class="flex items-center">
      <div
        class="hidden sm:block self-center whitespace-nowrap leading-[10px] ml-2 dark:sidebase-green-400"
      >
        <h1 class="font-CooperHewitt my-0 text-2xl font-light leading-5">
          11Hero
        </h1>
      </div>
    </div>
  </a>
</template>
